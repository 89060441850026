<template>
    <v-row no-gutters>
        <v-col cols="12">
            <UserBar :organizationId="$route.params.organizationId" :user="user" class="mb-6" v-if="user"></UserBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="user">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>User</v-app-bar-title>
                            <v-spacer/>
                            <span style="font-size: 0.7em;" class="text-end">User ID: {{ userIdText }}</span>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p>{{ user.name }}</p>
                            <p>{{ user.email }}</p>
                            <p>Created on {{ formatDate(user.created_on) }}</p>
                            <p>Ref: {{ user.ref }}</p>

                            <template v-if="Array.isArray(accountList) && accountList.length === 0">
                                <p>This user is not linked to any accounts</p>
                            </template>
                            <template v-if="Array.isArray(accountList) && accountList.length > 0">
                                <p class="mb-0">This user is linked to {{ accountList.length }} accounts:</p>
                                <p class="mb-0" v-for="(linkAccountUser, idx) in accountList" :key="idx">
                                    <router-link :to="{ name: 'organization-view-account', params: { organizationId: organization.id, accountId: linkAccountUser.account_id } }">
                                    {{ linkAccountUser.account.name }}
                                    </router-link>
                                </p>
                            </template>
                            <p><TextButton @click="dialogCreateAccount = true" underline color="blue">Create an account</TextButton></p>
                            <!-- <p>{{ JSON.stringify(user) }}</p> -->
                            <!-- <p>Amount {{ formatAmount(user.currency, user.amount_csu) }}
                                <span v-if="['paid', 'activated'].includes(user.status)" class="font-weight-bold">Paid</span>
                            </p> -->
                            <!-- <template v-if="user.status === 'activated'">
                                Activated
                                <v-btn text @click="reactivate" color="green darken-2">Reactivate</v-btn>
                            </template>
                            <template v-if="user.status === 'processed'">
                                Processed
                            </template> -->
                            <!-- TODO: show recent orders, payments, refunds, invoices, each in their own table, with a link to see all of them (link to search orders with this user id as a criteria, for example) -->
                            <!-- <v-simple-table>
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>Product ID</th>
                                            <th>Name</th>
                                            <th>Qty</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, idx) in user.content" :key="idx">
                                            <td>
                                                {{ item.product_id }}
                                            </td>
                                            <td>
                                                {{ item.name }}
                                            </td>
                                            <td>
                                                {{ item.quantity }}
                                            </td>
                                            <td>
                                                {{ formatAmount(item.currency, item.subtotal_csu) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table> -->
                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                    <font-awesome-icon :icon="['fas', 'trash']" class="red--text"></font-awesome-icon>
                                    <span class="ml-2">Delete User</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mt-2">Tap on the button below to delete this user. User must already be unlinked from all their accounts.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteUserImmediately">Delete User</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-dialog v-model="dialogCreateAccount" max-width="600">
                        <v-card class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="green--text text--darken-2">Create a new account</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>The user will be linked to the new account.</p>
                            </v-card-text>
                            <v-form @submit.prevent="createAccount" @keyup.enter.native="createAccount" class="px-5 mt-5">
                                <v-text-field v-model="newAccountName" ref="newAccountNameInput" dense outlined color="green darken-2" hint="A name for the new account. The customer can change it later." placeholder="Account name" :error-messages="newAccountNameInputError">
                                </v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="green darken-2 white--text" @click="createAccount" :disabled="!newAccountName">
                                    Create
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogCreateAccount = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import { ACCOUNT_TYPE_INDIVIDUAL } from '@/sdk/constants';
import UserBar from '@/components/UserBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import TextButton from '@/components/TextButton.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        UserBar,
        AccessDeniedOverlay,
        TextButton,
    },
    data: () => ({
        user: null,
        accountList: null,
        error: null,
        forbiddenError: false,
        dialogCreateAccount: false,
        newAccountName: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            focus: (state) => state.focus,
            organization: (state) => state.organization,
        }),
        userIdText() {
            return formatId(this.user.id);
        },
        userStatusText() {
            if (!this.user) {
                return 'Unknown';
            }
            return this.user.is_open ? 'Open' : 'Closed';
        },
    },
    watch: {
        focus() {
            this.loadUser();
            this.loadLinkedAccountList();
        },
        dialogCreateAccount(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newAccountNameInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadUser() {
            try {
                this.$store.commit('loading', { loadUser: true });
                const response = await this.$client.organization(this.$route.params.organizationId).user.get({ id: this.$route.params.userId });
                if (response) {
                    this.user = response;
                } else {
                    this.user = null;
                }
            } catch (err) {
                console.error('loadUser failed', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
            } finally {
                this.$store.commit('loading', { loadUser: false });
            }
        },
        async loadLinkedAccountList() {
            try {
                this.$store.commit('loading', { loadUser: true });
                const response = await this.$client.organization(this.$route.params.organizationId).linkAccountUser.search({ user_id: this.$route.params.userId });
                if (response?.list) {
                    this.accountList = response.list;
                } else {
                    this.accountList = null;
                }
            } catch (err) {
                console.error('loadUser failed', err);
            } finally {
                this.$store.commit('loading', { loadUser: false });
            }
        },
        viewUserLink(item) {
            return { name: 'organization-view-user', params: { organizationId: this.$route.params.organizationId, userId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        // userStatusDisplay(status) {
        //     if (status === 'paid') {
        //         return 'Paid, waiting to be processed..';
        //     }
        //     if (status === 'activated') {
        //         return 'Processing...';
        //     }
        //     if (status === 'completed') {
        //         return 'Fulfilled';
        //     }
        //     return null;
        // },
        async deleteUserImmediately() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.$store.commit('loading', { deleteUserImmediately: true });
                const response = await this.$client.organization(this.$route.params.organizationId).user.delete({ id: this.$route.params.userId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Deleted user' });
                    this.$router.go(-1);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete user' });
                }
            } catch (err) {
                console.error('deleteUserImmediately failed', err);
            } finally {
                this.$store.commit('loading', { deleteUserImmediately: false });
            }
        },
        async createAccount() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.$store.commit('loading', { createAccount: true });
                const createAccountResponse = await this.$client.organization(this.$route.params.organizationId).account.create({ name: this.newAccountName, type: ACCOUNT_TYPE_INDIVIDUAL });
                if (!createAccountResponse?.isCreated) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create account' });
                    return;
                }
                const linkUserResponse = await this.$client.organization(this.$route.params.organizationId).linkAccountUser.create({ account_id: createAccountResponse.id, user_id: this.$route.params.userId, is_admin: true });
                if (!linkUserResponse?.isCreated) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to link user to account' });
                    return;
                }
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Created account' });
                this.dialogCreateAccount = false;
                this.loadLinkedAccountList();
            } catch (err) {
                console.error('createAccount failed', err);
            } finally {
                this.$store.commit('loading', { createAccount: false });
            }
        },
    },
    mounted() {
        this.loadUser();
        this.loadLinkedAccountList();
    },
};
</script>
