<template>
    <v-card flat>
        <v-toolbar flat color="grey lighten-5">
            <v-toolbar-title>
                <template v-if="user">
                    <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
                    <span class="ml-4">{{ user.name }} &lt;{{ user.email }}&gt;</span>
                </template>
                <template v-else>
                    <!-- NOTE: we use grey here so that administrators can tell the difference between an unknown user (grey) and a known user whose name is "Unknown user" (black) -->
                    <font-awesome-icon :icon="['fas', 'user']" color="grey"></font-awesome-icon>
                    <span class="ml-4 grey--text">Unknown user</span>
                </template>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
                <font-awesome-icon :icon="['fas', 'globe']"></font-awesome-icon>
            </v-btn> -->
            <!-- <template #extension v-if="userId">
                <span style="font-size: 0.75em;">
                    <router-link :to="{ name: 'user-view-domain', params: { userId, domain } }">Overview</router-link>
                    <span class="mx-4"></span>
                    <router-link :to="{ name: 'user-edit-dns', params: { userId, domain } }">DNS Records</router-link>
                </span>
            </template> -->
            <span style="font-size: 0.75em;" v-if="user">
                <template v-if="$route.name === 'organization-view-user'">
                    <span class="font-weight-bold">Overview</span>
                </template>
                <template v-else>
                    <router-link :to="{ name: 'organization-view-user', params: { organizationId, userId: user.id } }">Overview</router-link>
                </template>
                <!-- <span class="mx-4"></span>
                <router-link :to="{ name: 'user-edit-dns', params: { userId, productId } }">DNS Records</router-link> -->
            </span>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    props: ['organizationId', 'user'],
};
</script>
